import { Theme, LinearGradientDirection as Direction } from '../../types';
import { pxToRem } from '../../../styles/utils';

const OPTIONS = {
  COLORS: {
    SPEARMINT: '#80D6B0',
    GREEN: '#3BBFAD',
    BLACK: '#000000',
    PEPPERMINT: '#589EA6',
    GINGER: '#F4BA42',
    LIGHT_YELLOW: '#FFFAEF',
    CINNAMINT: '#D66755',
    GRAY: '#C4C4C4',
    LIGHT_GRAY: '#D0D0D0',
    LIGHTER_GRAY: '#F1F1F1',
    DARK_GRAY: '#757575',
    LIGHT_BLUE: '#8AC2E0',
    MUTED_NAVY: '#9AB5B8',
    TRANSPARENT: 'transparent',
    RED: '#D31400',
    LIGHT_GREEN: '#EEFBF5',
    SAND: '#FFF3DF',
    LIMEGREEN: '#BBF174',
    PRIMARY: {
      TEAL: '#00D9B4',
      BLACK: '#1D252C',
      LIGHT_GREY: '#F3F3F3',
      WHITE: '#FFFFFF'
    },
    SECONDARY: {
      MINT: '#67D7A5',
      MID_BLUE: '#4FA4AE',
      NAVY: '#035C67',
      VIOLET: '#5A6AFF',
      BLUE: '#344099',
      TEXT: '#565B61'
    },
    TERTIARY: {
      HIBISCUS: '#DE5D83',
      YELLOW: '#FFBA2A',
      PURPLE: '#A95EDB'
    },
    SUPPLEMENTARY: {
      LIGHT_TEAL: '#99E4D7',
      LIGHT_MINT: '#99EDC5',
      LIGHT_MID_BLUE: '#99D8D8',
      LIGHT_NAVY: '#60AFB9',
      LIGHT_VIOLET: '#9CA5FE',
      LIGHT_BLUE: '#7580CB',
      LIGHT_HIBISCUS: '#FFA9C2',
      LIGHT_YELLOW: '#FAD799',
      LIGHT_PURPLE: '#D698FF'
    }
  },
  GLOWS: {
    PRIMARY: {
      TEAL: (dir: Direction = Direction.bottom) =>
        `linear-gradient(${dir}, #00D9B4, #99E4D7)`
    },
    SECONDARY: {
      MID_BLUE: (dir: Direction = Direction.bottom) =>
        `linear-gradient(${dir}, #4FA4AE, #99D8D8)`,
      NAVY: (dir: Direction = Direction.bottom) =>
        `linear-gradient(${dir}, #035C67, #60AFB9)`,
      VIOLET: (dir: Direction = Direction.bottom) =>
        `linear-gradient(${dir}, #5A6AFF, #9CA5FE)`,
      BLUE: (dir: Direction = Direction.bottom) =>
        `linear-gradient(${dir}, #344099, #7580CB)`
    },
    TERTIARY: {
      HIBISCUS: (dir: Direction = Direction.bottom) =>
        `linear-gradient(${dir}, #DE5D83, #FFA9C2)`,
      YELLOW: (dir: Direction = Direction.bottom) =>
        `linear-gradient(${dir}, #FFBA2A, #FAD799)`,
      PURPLE: (dir: Direction = Direction.bottom) =>
        `linear-gradient(${dir}, #A95EDB, #D698FF)`
    },
    SUPPLEMENTARY: {
      TEAL_TO_MINT: (dir: Direction = Direction.bottom) =>
        `linear-gradient(${dir}, #00D9B4, #67D7A5)`,
      NAVY_TO_TEAL: (dir: Direction = Direction.bottom) =>
        `linear-gradient(${dir}, #035C67, #00D9B4)`,
      VIOLET_TO_PURPLE: (dir: Direction = Direction.bottom) =>
        `linear-gradient(${dir}, #5A6AFF, #D698FF)`,
      HIBISCUS_TO_YELLOW: (dir: Direction = Direction.bottom) =>
        `linear-gradient(${dir}, #DE5D83, #FFBA2A)`,
      TEAL_TO_LIGHT_MINT: (dir: Direction = Direction.bottom) =>
        `linear-gradient(${dir}, #00D9B4, #99EDC5)`
    },
    BEAM: {
      TEAL: (dir: Direction = Direction.bottom) =>
        `linear-gradient(${dir}, #00D9B4 100%, #00D9B4 0%)`,
      YELLOW: (dir: Direction = Direction.bottom) =>
        `linear-gradient(${dir}, #FFBA2A 100%, #FFBA2A 0%)`,
      VIOLET: (dir: Direction = Direction.bottom) =>
        `linear-gradient(${dir}, #5A6AFF 100%, #5A6AFF 0%)`,
      WHITE: (dir: Direction = Direction.bottom) =>
        `linear-gradient(${dir}, #FFFFFF 100%, #FFFFFF 0%)`
    }
  },
  FONTS: {
    GLRepro:
      'Greenlight Repro, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif',
    GLReproDisplay:
      'Greenlight Repro Display, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif'
  },
  FONT_SIZES: {
    '3XS': pxToRem(10),
    '2XS': pxToRem(12),
    XS: pxToRem(13),
    S: pxToRem(14),
    SM: pxToRem(16),
    SMM: pxToRem(17),
    M: pxToRem(18),
    ML: pxToRem(20),
    L: pxToRem(22),
    XL: pxToRem(26),
    '2XL': pxToRem(28),
    '3XL': pxToRem(32),
    '4XL': pxToRem(42),
    '5XL': pxToRem(50)
  },
  LINE_HEIGHTS: {
    '3XS': 'auto',
    '2XS': 'auto',
    XS: 'auto',
    S: pxToRem(18),
    SM: pxToRem(20),
    SMM: pxToRem(22),
    M: pxToRem(24),
    ML: pxToRem(26),
    L: pxToRem(32),
    XL: pxToRem(36),
    '2XL': pxToRem(40),
    '3XL': pxToRem(36),
    '4XL': pxToRem(48),
    '5XL': pxToRem(55)
  },
  SPACING: {
    '2XS': pxToRem(2),
    XS: pxToRem(4),
    S: pxToRem(8),
    SM: pxToRem(12),
    M: pxToRem(16),
    ML: pxToRem(24),
    L: pxToRem(32),
    XL: pxToRem(48),
    '2XL': pxToRem(64),
    '3XL': pxToRem(96),
    '4XL': pxToRem(128),
    '5XL': pxToRem(166),
    CONTENT_MARGIN: pxToRem(36),
    CONTENT_MARGIN_TABLET: pxToRem(160),
    BLOCK_MARGIN: pxToRem(12),
    BLOCK_MARGIN_TABLET: pxToRem(48),
    BLOCK_PADDING: pxToRem(24),
    BLOCK_PADDING_TABLET: pxToRem(112),
    MASONRY_GUTTER_DESKTOP: pxToRem(50),
    PLANS_MARGIN: pxToRem(85),
    HIDDEN_HEADER: pxToRem(-28),
    QSG_PADDING: pxToRem(84)
  },
  BORDER_RADIUS: {
    CTA_BUTTON_INSIDE: pxToRem(8),
    CTA_BUTTON_INSIDE_DROPSHADOW: pxToRem(8),
    INPUT_BUTTON: pxToRem(8),
    INPUT_MOBILE_NAV: pxToRem(8),
    INPUT_SMALL: pxToRem(12),
    INPUT_LARGE: pxToRem(16)
  },
  LINEAR_GRADIENT: {
    HIBISCUS: ['#DE5D83', '#FFA9C2'],
    YELLOW: ['#FFBA2A', '#FAD799'],
    TEAL: ['#00D9B4', '#99E4D7'],
    NAVY: ['#035C67', '#60AFB9'],
    VIOLET: ['#5A6AFF', '#9CA5FE'],
    BLUE: ['#344099', '#7580CB'],
    MINT: ['#00D9B4', '#67D7A5']
  }
};

export const GreenlightTheme: Theme = {
  name: 'Greenlight',
  color: {
    unset: 'unset',
    transparent: 'transparent',
    brand: OPTIONS.COLORS.SPEARMINT,
    footerBg: OPTIONS.COLORS.PRIMARY.BLACK,
    footerBgSecondary: OPTIONS.COLORS.BLACK,
    footerText: OPTIONS.COLORS.PRIMARY.WHITE,
    footerTextSecondary: OPTIONS.COLORS.PRIMARY.LIGHT_GREY,
    footerTextSupplementary: OPTIONS.COLORS.PRIMARY.LIGHT_GREY,
    defaultText: OPTIONS.COLORS.PRIMARY.BLACK,
    secondaryText: OPTIONS.COLORS.SECONDARY.TEXT,
    white: OPTIONS.COLORS.PRIMARY.WHITE,
    black: OPTIONS.COLORS.PRIMARY.BLACK,
    inputBorder: OPTIONS.COLORS.GRAY,
    darkButton: OPTIONS.COLORS.SECONDARY.NAVY,
    lightButton: OPTIONS.COLORS.SPEARMINT,
    buttonHover: OPTIONS.COLORS.GREEN,
    sliderSlide1: OPTIONS.COLORS.SPEARMINT,
    sliderSlide2: OPTIONS.COLORS.GREEN,
    sliderSlide3: OPTIONS.COLORS.GINGER,
    headerBg: OPTIONS.COLORS.PRIMARY.WHITE,
    headerAltBg: OPTIONS.COLORS.SECONDARY.NAVY,
    headerLink: OPTIONS.COLORS.PRIMARY.BLACK,
    headerAltLink: OPTIONS.COLORS.PRIMARY.WHITE,
    headerLinkHover: OPTIONS.COLORS.GREEN,
    headerLogo: OPTIONS.COLORS.GREEN,
    headerAltLogo: OPTIONS.COLORS.PRIMARY.WHITE,
    milestoneText: OPTIONS.COLORS.PRIMARY.BLACK,
    infoText: OPTIONS.COLORS.PRIMARY.WHITE,
    infoBg: OPTIONS.COLORS.SECONDARY.NAVY,
    asFeaturedOnBg: OPTIONS.COLORS.SECONDARY.NAVY,
    homepageHeroBg: OPTIONS.COLORS.GINGER,
    homepageHeroCtaBg: OPTIONS.COLORS.GREEN,
    collapsibleBorder: OPTIONS.COLORS.LIGHT_GRAY,
    inlineAnchor: OPTIONS.COLORS.SECONDARY.NAVY,
    inlineAnchorHover: OPTIONS.COLORS.GREEN,
    lightInlineAnchor: OPTIONS.COLORS.PRIMARY.WHITE,
    playIconBackground: OPTIONS.COLORS.SECONDARY.NAVY,
    playIconCenter: OPTIONS.COLORS.SPEARMINT,
    pressPostBg: OPTIONS.COLORS.LIGHTER_GRAY,
    pressHeaderBg1: OPTIONS.COLORS.PEPPERMINT,
    pressHeaderBg2: OPTIONS.COLORS.SECONDARY.NAVY,
    errorBg: OPTIONS.COLORS.PRIMARY.BLACK,
    quickStartGuideToggle: OPTIONS.COLORS.PRIMARY.LIGHT_GREY,
    blogPostHeroBg: OPTIONS.COLORS.GINGER,
    tagBg: OPTIONS.COLORS.PRIMARY.WHITE,
    tagText: OPTIONS.COLORS.PRIMARY.BLACK,
    blockquoteBg: OPTIONS.COLORS.GREEN,
    blockquoteText: OPTIONS.COLORS.PRIMARY.BLACK,
    socialIcon: OPTIONS.COLORS.SECONDARY.MID_BLUE,
    socialIconHover: OPTIONS.COLORS.SECONDARY.NAVY,
    socialIconLight: OPTIONS.COLORS.PRIMARY.WHITE,
    socialIconLightHover: OPTIONS.COLORS.PRIMARY.BLACK,
    socialIconSupplementary: OPTIONS.COLORS.SUPPLEMENTARY.LIGHT_TEAL,
    featuredBlogPostBg: OPTIONS.COLORS.SECONDARY.NAVY,
    featuredBlogPostText: OPTIONS.COLORS.PRIMARY.WHITE,
    leadCaptureBg: OPTIONS.COLORS.LIGHT_YELLOW,
    leadCaptureText: OPTIONS.COLORS.CINNAMINT,
    navbarLink: OPTIONS.COLORS.PRIMARY.BLACK,
    mobileMenuGetStartedBg: OPTIONS.COLORS.PRIMARY.LIGHT_GREY,
    desktopNavSublinkHoverColor: OPTIONS.COLORS.SECONDARY.NAVY,
    desktopNavSublinkBorderColor: OPTIONS.COLORS.SPEARMINT,
    selectInputLabelBg: OPTIONS.COLORS.PRIMARY.LIGHT_GREY,
    selectInputLabelText: OPTIONS.COLORS.PRIMARY.BLACK,
    inputLabelText: OPTIONS.COLORS.DARK_GRAY,
    inputBorderColor: OPTIONS.COLORS.GRAY,
    privacyLinkText: OPTIONS.COLORS.LIGHT_BLUE,
    privacyTextBorderBottom: OPTIONS.COLORS.PRIMARY.LIGHT_GREY,
    emailInputBorder: OPTIONS.COLORS.GREEN,
    emailInputBackground: OPTIONS.COLORS.LIGHT_GREEN,
    emailInputBorderError: OPTIONS.COLORS.RED,
    giftingSubscriptionButton: OPTIONS.COLORS.TERTIARY.YELLOW,
    radioButtonPrimary: OPTIONS.COLORS.PRIMARY.TEAL,
    radioButtonSecondary: OPTIONS.COLORS.GRAY,
    textInputPrimary: OPTIONS.COLORS.PRIMARY.TEAL,
    textInputInvalid: OPTIONS.COLORS.TERTIARY.HIBISCUS,
    formToastBorder: OPTIONS.COLORS.SUPPLEMENTARY.LIGHT_YELLOW,
    formToastBackground: OPTIONS.COLORS.SAND,
    giftingSubscriptionImageBackground: OPTIONS.COLORS.PRIMARY.TEAL,
    giftingSubscriptionBackground: OPTIONS.COLORS.PRIMARY.LIGHT_GREY
  },
  cfColor: {
    spearmint: OPTIONS.COLORS.SPEARMINT,
    green: OPTIONS.COLORS.GREEN,
    ginger: OPTIONS.COLORS.GINGER,
    cinnamint: OPTIONS.COLORS.CINNAMINT,
    blackberry: OPTIONS.COLORS.PRIMARY.BLACK,
    sweetmint: OPTIONS.COLORS.SECONDARY.NAVY,
    white: OPTIONS.COLORS.PRIMARY.WHITE,
    mutedNavy: OPTIONS.COLORS.MUTED_NAVY,
    transparent: OPTIONS.COLORS.TRANSPARENT,
    limegreen: OPTIONS.COLORS.LIMEGREEN
  },
  buttonColor: {
    dark: OPTIONS.COLORS.SECONDARY.NAVY,
    light: OPTIONS.COLORS.GREEN,
    darkHover: OPTIONS.COLORS.GREEN,
    lightHover: OPTIONS.COLORS.GINGER
  },
  fontFamily: { default: OPTIONS.FONTS.GLRepro },
  fontSize: {
    '3xs': OPTIONS.FONT_SIZES['3XS'],
    '2xs': OPTIONS.FONT_SIZES['2XS'],
    xs: OPTIONS.FONT_SIZES.XS,
    s: OPTIONS.FONT_SIZES.S,
    sm: OPTIONS.FONT_SIZES.SM,
    smm: OPTIONS.FONT_SIZES.SMM,
    m: OPTIONS.FONT_SIZES.M,
    ml: OPTIONS.FONT_SIZES.ML,
    l: OPTIONS.FONT_SIZES.L,
    xl: OPTIONS.FONT_SIZES.XL,
    '2xl': OPTIONS.FONT_SIZES['2XL'],
    '3xl': OPTIONS.FONT_SIZES['3XL'],
    '4xl': OPTIONS.FONT_SIZES['4XL'],
    '5xl': OPTIONS.FONT_SIZES['5XL'],
    h1_0: pxToRem(34),
    h1_tablet: OPTIONS.FONT_SIZES['5XL'],
    h2_0: OPTIONS.FONT_SIZES['2XL'],
    h2_tablet: OPTIONS.FONT_SIZES['3XL'],
    h2_desktop: OPTIONS.FONT_SIZES['4XL'],
    h3_0: OPTIONS.FONT_SIZES.L,
    h3_tablet: OPTIONS.FONT_SIZES['XL'],
    h4_0: OPTIONS.FONT_SIZES.M,
    h4_tablet: OPTIONS.FONT_SIZES['L'],
    h5_0: OPTIONS.FONT_SIZES.SMM,
    h5_tablet: OPTIONS.FONT_SIZES.M,
    h6_0: OPTIONS.FONT_SIZES.SM,
    h6_tablet: OPTIONS.FONT_SIZES.M,
    p_0: OPTIONS.FONT_SIZES.SM,
    p_desktop: OPTIONS.FONT_SIZES.M,
    small_p_0: OPTIONS.FONT_SIZES['XS'],
    small_p_tablet: OPTIONS.FONT_SIZES.S,
    headerLink_0: OPTIONS.FONT_SIZES['2XL'],
    headerLink_desktop: OPTIONS.FONT_SIZES.M,
    button_0: OPTIONS.FONT_SIZES.S,
    button_desktop: OPTIONS.FONT_SIZES.SM,
    input_0: OPTIONS.FONT_SIZES['3XS'],
    input_tablet: OPTIONS.FONT_SIZES['2XS'],
    input_desktop: OPTIONS.FONT_SIZES.XS,
    blogTitle_0: OPTIONS.FONT_SIZES.L,
    blogTitle_tablet: OPTIONS.FONT_SIZES['XL'],
    blogTitle_desktop: OPTIONS.FONT_SIZES['4XL'],
    tag_0: OPTIONS.FONT_SIZES['3XS'],
    tag_tablet: OPTIONS.FONT_SIZES['2XS'],
    tag_desktop: OPTIONS.FONT_SIZES['XS'],
    mobile_navbar_menu_link: OPTIONS.FONT_SIZES['L'],
    mobile_navbar_menu_sublink: OPTIONS.FONT_SIZES['SM'],
    desktop_navbar_navlink: OPTIONS.FONT_SIZES['SM'],
    desktop_navbar_nav_sublink: OPTIONS.FONT_SIZES['S']
  },
  lineHeight: {
    '3xs': OPTIONS.LINE_HEIGHTS['3XS'],
    '2xs': OPTIONS.LINE_HEIGHTS['2XS'],
    xs: OPTIONS.LINE_HEIGHTS.XS,
    s: OPTIONS.LINE_HEIGHTS.S,
    sm: OPTIONS.LINE_HEIGHTS.SM,
    smm: OPTIONS.LINE_HEIGHTS.SMM,
    m: OPTIONS.LINE_HEIGHTS.M,
    ml: OPTIONS.LINE_HEIGHTS.ML,
    l: OPTIONS.LINE_HEIGHTS.L,
    xl: OPTIONS.LINE_HEIGHTS.XL,
    '2xl': OPTIONS.LINE_HEIGHTS['2XL'],
    '3xl': OPTIONS.LINE_HEIGHTS['3XL'],
    '4xl': OPTIONS.LINE_HEIGHTS['4XL'],
    '5xl': OPTIONS.LINE_HEIGHTS['5XL'],
    h1_0: OPTIONS.LINE_HEIGHTS.XL,
    h1_tablet: OPTIONS.LINE_HEIGHTS['5XL'],
    h2_0: OPTIONS.LINE_HEIGHTS.L,
    h2_tablet: OPTIONS.LINE_HEIGHTS['3XL'],
    h2_desktop: OPTIONS.LINE_HEIGHTS['4XL'],
    h3_0: OPTIONS.LINE_HEIGHTS.M,
    h3_tablet: OPTIONS.LINE_HEIGHTS.L,
    h4_0: OPTIONS.LINE_HEIGHTS.S,
    h4_tablet: OPTIONS.LINE_HEIGHTS.M,
    h5_0: OPTIONS.FONT_SIZES.S,
    h5_tablet: OPTIONS.FONT_SIZES.M,
    h6_0: OPTIONS.FONT_SIZES.S,
    h6_tablet: OPTIONS.FONT_SIZES.M,
    p_0: OPTIONS.LINE_HEIGHTS.SM,
    p_desktop: OPTIONS.LINE_HEIGHTS.ML,
    small_p_0: OPTIONS.LINE_HEIGHTS.SM,
    small_p_tablet: OPTIONS.LINE_HEIGHTS.SM,
    headerLink_0: OPTIONS.LINE_HEIGHTS['2XL'],
    headerLink_desktop: OPTIONS.LINE_HEIGHTS.M,
    button_0: 'auto',
    button_desktop: 'auto',
    input_0: 'auto',
    input_tablet: 'auto',
    input_desktop: 'auto',
    blogTitle_0: OPTIONS.LINE_HEIGHTS.M,
    blogTitle_tablet: OPTIONS.LINE_HEIGHTS.L,
    blogTitle_desktop: OPTIONS.LINE_HEIGHTS['4XL'],
    tag_0: OPTIONS.LINE_HEIGHTS['3XS'],
    tag_tablet: OPTIONS.LINE_HEIGHTS['2XS'],
    tag_desktop: OPTIONS.LINE_HEIGHTS['XS'],
    mobile_navbar_menu_link: OPTIONS.FONT_SIZES['L'],
    mobile_navbar_menu_sublink: OPTIONS.FONT_SIZES['SM'],
    desktop_navbar_navlink: OPTIONS.FONT_SIZES['SM'],
    desktop_navbar_nav_sublink: OPTIONS.FONT_SIZES['S']
  },
  fontWeight: {
    '100': '100',
    '200': '200',
    '300': '300',
    '400': '400',
    '500': '500',
    '600': '600',
    '700': '700',
    '800': '800',
    '900': '900',
    h1: '500',
    h2: '500',
    h3: '500',
    h4: '500',
    p: '400',
    inlineAnchor: '600',
    footerAnchor: '500',
    secondaryFooterAnchor: '550',
    button: '500',
    bold: '500'
  },
  spacing: {
    '2xs': OPTIONS.SPACING['2XS'],
    xs: OPTIONS.SPACING.XS,
    s: OPTIONS.SPACING.S,
    sm: OPTIONS.SPACING.SM,
    m: OPTIONS.SPACING.M,
    ml: OPTIONS.SPACING.ML,
    l: OPTIONS.SPACING.L,
    xl: OPTIONS.SPACING.XL,
    '2xl': OPTIONS.SPACING['2XL'],
    '3xl': OPTIONS.SPACING['3XL'],
    '4xl': OPTIONS.SPACING['4XL'],
    '5xl': OPTIONS.SPACING['5XL'],
    '0': pxToRem(0),
    auto: 'auto',
    collapsibleVert: pxToRem(20),
    contentMargin: OPTIONS.SPACING.CONTENT_MARGIN,
    contentMargin_tablet: OPTIONS.SPACING.CONTENT_MARGIN_TABLET,
    blockMargin: OPTIONS.SPACING.BLOCK_MARGIN,
    blockMargin_tablet: OPTIONS.SPACING.BLOCK_MARGIN_TABLET,
    blockMarginY: OPTIONS.SPACING.XL,
    blockMarginY_tablet: OPTIONS.SPACING['2XL'],
    blockMarginY_desktop: OPTIONS.SPACING['4XL'],
    blockPadding: OPTIONS.SPACING.BLOCK_PADDING,
    blockPadding_tablet: OPTIONS.SPACING.BLOCK_PADDING_TABLET,
    plansMargin: OPTIONS.SPACING.PLANS_MARGIN,
    topMargin: OPTIONS.SPACING.BLOCK_MARGIN,
    topMargin_tablet: OPTIONS.SPACING.BLOCK_MARGIN_TABLET,
    masonryGutter: OPTIONS.SPACING.L,
    masonryGutter_desktop: OPTIONS.SPACING.MASONRY_GUTTER_DESKTOP,
    hiddenHeader: OPTIONS.SPACING.HIDDEN_HEADER,
    quickStartGuideTop: OPTIONS.SPACING.QSG_PADDING,
    splitForm: pxToRem(40)
  },
  borderRadius: {
    unset: 'unset',
    '0': '0',
    block: pxToRem(64),
    block_desktop: pxToRem(104),
    sliderBlock: pxToRem(64),
    sliderBlock_desktop: pxToRem(96),
    innerBlock: pxToRem(40),
    pressPost: pxToRem(56),
    pressPostHeader: pxToRem(24),
    quickStartGuideBox: pxToRem(56),
    input: OPTIONS.BORDER_RADIUS.INPUT_SMALL,
    input_desktop: OPTIONS.BORDER_RADIUS.INPUT_LARGE,
    input_mobile_nav: OPTIONS.BORDER_RADIUS.INPUT_MOBILE_NAV,
    input_button: OPTIONS.BORDER_RADIUS.INPUT_BUTTON,
    button: OPTIONS.BORDER_RADIUS.INPUT_SMALL,
    button_desktop: OPTIONS.BORDER_RADIUS.INPUT_LARGE,
    blogHero_0: pxToRem(56),
    blogHero_desktop: pxToRem(80),
    tag_0: pxToRem(8),
    tag_tablet: pxToRem(12),
    tag_desktop: pxToRem(16),
    blockquote_0: pxToRem(56),
    blockquote_tablet: pxToRem(64),
    greenlight_border_radius: pxToRem(56),
    popup_border_radius: pxToRem(20),
    planHeaderMobile: pxToRem(32),
    planHeaderDesktop: pxToRem(40),
    milestoneShape: pxToRem(64),
    eyebrow: '16px',
    desktop_navbar_dropdown: pxToRem(16),
    cta_button_inside: OPTIONS.BORDER_RADIUS.CTA_BUTTON_INSIDE,
    cta_button_inside_dropshadow:
      OPTIONS.BORDER_RADIUS.CTA_BUTTON_INSIDE_DROPSHADOW,
    standard_border_radius: pxToRem(48)
  },
  linearGradient: OPTIONS.LINEAR_GRADIENT
};

export { OPTIONS as GreenlightThemeOptions };
