import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import { NextParsedUrlQuery } from 'next/dist/server/request-meta';

import {
  IBlogPost,
  IGlobalConfiguration,
  IGlobalConfigurationFields,
  ILearningCenterConfiguration,
  ILearningCenterConfigurationFields,
  ILearningCenterArticle,
  IPage,
  IPageFields
} from '@/types/contentful';

import { Cookies } from '../../modules/cookies/getServerSideCookies';
import { UserData } from '../../lib/referrals/getUserDataById';
import { usePublicRuntimeConfig } from 'src/hooks/usePublicRuntimeConfig';

export enum envEnum {
  local = 'development',
  development = 'development',
  preview = 'preview',
  staging = 'staging',
  production = 'production'
}

export enum returningUserEnvEnum {
  local = 'local',
  development = 'dev',
  staging = 'staging',
  production = 'production'
}

export type UrlByEnv = {
  [key in envEnum]?: string;
};

export interface PageFieldProps {
  page?: IPageFields;
  config?: IGlobalConfigurationFields;
  articleConfig?: ILearningCenterConfigurationFields;
  webregIncident?: boolean;
  cookies?: Cookies;
  query?: any;
  advocateUserData?: UserData | null;
  minAprAmount?: number;
  maxAprAmount?: number;
  effectiveDateConversion?: any;
  isReturning?: boolean;
  embeddedPosts?: any[];
}

export interface PageProps {
  query?: NextParsedUrlQuery;
  slug?: string | null;
  pageData?: IPage;
  blogPostData?: IBlogPost;
  articleData?: ILearningCenterArticle;
  globalConfig?: IGlobalConfiguration;
  learningCenterConfig?: ILearningCenterConfiguration;
  cookies?: Cookies;
  advocateUserData?: UserData;
  minAprAmount?: number;
  maxAprAmount?: number;
  effectiveDateConversion?: any;
  embeddedPosts?: any[];
}

export interface PageProvider extends PageProps {
  children?: ReactNode | ReactNode[];
}

export const PageContext = createContext<PageFieldProps>({} as PageFieldProps);

export const usePageDataContext = () => useContext(PageContext);

export function PageDataProvider({
  globalConfig,
  learningCenterConfig,
  pageData,
  query,
  cookies,
  children,
  advocateUserData,
  minAprAmount,
  maxAprAmount,
  effectiveDateConversion,
  embeddedPosts
}: PageProvider) {
  const [webregIncident, setIncidentResponse] = useState(
    globalConfig?.fields?.enableIncidentResponse ?? false
  );

  useEffect(() => {
    window.setIncident = setIncidentResponse;
  }, []);

  const { nodeEnv } = usePublicRuntimeConfig();
  const cookieName = `__onboarding-root-session-${
    returningUserEnvEnum[nodeEnv as keyof typeof returningUserEnvEnum]
  }`;
  const isReturning = Boolean(
    cookies &&
      cookies[cookieName as keyof typeof cookies] !== undefined &&
      cookies[cookieName as keyof typeof cookies] !== null
  );

  return (
    <PageContext.Provider
      value={{
        config: globalConfig?.fields,
        articleConfig: learningCenterConfig?.fields,
        page: pageData?.fields,
        query,
        webregIncident,
        cookies: cookies ?? ({} as Cookies),
        advocateUserData,
        minAprAmount,
        maxAprAmount,
        effectiveDateConversion,
        isReturning,
        embeddedPosts
      }}
    >
      {children}
    </PageContext.Provider>
  );
}
