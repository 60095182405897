import mixpanel from 'mixpanel-browser';
import logger from '@greenlightme/logger';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const { mixPanelPid } = publicRuntimeConfig;

// const enabledEnv = ['development', 'staging', 'production'].includes(nodeEnv);

export const initializeMixpanel = () => {
  //   if (enabledEnv) {
  logger.info('Initializing Mixpanel.');
  if (mixPanelPid && mixPanelPid !== '') {
    mixpanel.init(mixPanelPid, {
      record_sessions_percent: 100,
      persistence: 'localStorage',
      record_block_selector: '.mp-block-mask',
      record_mask_text_selector: '.mp-mask'
    });

    mixpanel.start_session_recording();
    logger.info('Starting Mixpanel session replay.');
  } else {
    logger.info('Unable to start Mixpanel');
  }
  //   }
};
