import React from 'react';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { Session } from 'next-auth';
import { Global, css } from '@emotion/react';

import { addRumContext, initializeRum } from '../utils/datadogRum';
import { initializeMixpanel } from '../utils/mixpanel';

import { AuthProvider } from '../context/Auth';
import { UserProvider } from '../context/User';
import { PageDataProvider } from '../context/PageData';
import { OptimizelyProvider } from '../context/Optimizely';
import { SubscriptionsProvider } from '../context/Subscriptions';

import { ThemeProvider } from '../theme';
import { GreenlightTheme } from '../theme/themes/greenlight';

import { glNormalize } from '../components/Metadata/gl-normalize.styles';

import '@/styles/global.css';
import '@/fonts/style.css';
import '@/styles/normalize.css';
import '@/styles/bright-edge.css';
import '@/styles/outgrow.css';

initializeRum();
initializeMixpanel();

export default function App({
  Component,
  pageProps
}: AppProps<{ session: Session | null }>) {
  return (
    <AuthProvider {...pageProps}>
      <UserProvider {...pageProps}>
        <PageDataProvider {...pageProps}>
          <OptimizelyProvider {...pageProps}>
            <SubscriptionsProvider>
              <ThemeProvider theme={GreenlightTheme}>
                <Global
                  styles={css`
                    ${glNormalize(GreenlightTheme)}
                  `}
                />
                <Component {...pageProps} />
              </ThemeProvider>
            </SubscriptionsProvider>
          </OptimizelyProvider>
        </PageDataProvider>
      </UserProvider>
    </AuthProvider>
  );
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  // Don't report the render or route change to render metrics
  if (
    !['Next.js-render', 'Next.js-route-change-to-render'].includes(metric.name)
  ) {
    addRumContext(metric.name, metric);
  }
}
